import React from "react";
import SVGIcon from "../../../../utils/SVGIcon";

interface MyComponent {
  menu: any;
}
const FooterServiceSection: React.FC<MyComponent> = ({ menu }) => {
  return (
    <div className="serviceSection">
      <h4 className="footerTitle">Our Services</h4>
      <ul className="footerMenu">
        {menu?.map((item: any, index: number) => {
          return (
            <li key={index}>
              <a href={item.link} title={item.name} target="_blank" rel="noreferrer">
                <SVGIcon icon="rightTopArrow" />
                {item.name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FooterServiceSection;
