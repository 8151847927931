import { Skeleton, Tabs } from 'antd';
import React, { useState } from 'react';
import benefitsData from "./TopBenefits.json";
import type { TabsProps } from 'antd';
import "../../assets/styles/commonSections/topBenefits.less";
import bgImage from "../../assets/images/topBenefitsBg.png";
import dots from "../../assets/images/benefitsDotsImages.png";
import rightCircle from "../../assets/images/benefitsRightIcon.png";
import bottomCircle from "../../assets/images/benifitsBottomCircle.png";
import { ReactComponent as StarImg } from "../../assets/images/benefitsPlusIcon.svg";
import { ReactComponent as CardImages1 } from "../../assets/images/icons/Improved.svg";
import { ReactComponent as CardImages2 } from "../../assets/images/icons/Enhanced.svg";
import { ReactComponent as CardImages3 } from "../../assets/images/icons/Increased.svg";
import { ReactComponent as CardImages4 } from "../../assets/images/icons/Quicker.svg";
import { ReactComponent as CardImages5 } from "../../assets/images/icons/WorkFlow.svg";
import { ReactComponent as CardImages6 } from "../../assets/images/icons/Decisions.svg";
import { ReactComponent as CardImages7 } from "../../assets/images/icons/Collaboration.svg";

import Slider from "react-slick";

const TopBenefits = () => {
	const cityOfficials: any = benefitsData?.tabData;
	const [loading, setLoading] = useState<boolean>(false);
	const cardImages: any = {
		access: <CardImages1 />,
		accountability: <CardImages2 />,
		engagement: <CardImages3 />,
		response: <CardImages4 />,
		workFlow: <CardImages5 />,
		decisions: <CardImages6 />,
		collaboration: <CardImages7 />
	}

	const settings = {
		dots: true,
		arrows: false,
		autoplay: true,
		centerMode: true,
		centerPadding: "00px",
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					centerPadding: "00",
				},
			},

		],
	};

	const testimonialItem = (title: string, description: string, index: number, svg: any) => (
		<Skeleton loading={loading} avatar paragraph={{ rows: 4 }}>
			<div className="testimonialItem" key={index}>

				<div className='icon'>
					{cardImages[svg]}
				</div>
				<h3 className="title">{title}</h3>
				<p>{description}</p>
			</div>
		</Skeleton>
	);

	const onChange = (key: string) => {
		console.log(key);
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 500);
	};

	const items: TabsProps['items'] = cityOfficials.map((item: any) => ({
		key: item.key,
		label: item.label,
		children: (
			<Slider {...settings} slidesToShow={Math.min(item.content.length, 4)}   >
				{item.content.map((content: any, index: number) => (
					<div key={index} className="moduleList">
						{testimonialItem(content.title, content.description, index, content.svg)}
					</div>
				))}
			</Slider>
		),
	}));



	return (
		<section id='topBenefits' >
			<div className="topBenefits-bg" style={{ backgroundImage: `url(${bgImage})` }}>
				<img src={dots} alt='dots' className='dotsImg' />
				<div className='greenStar'>
					<StarImg />
				</div>
				<div className='whiteBottomStar'>
					<StarImg />
				</div>
				<div className="topBenefitsContainer">
					<div className="upperContent">
						<h2 className='titleSection'>{benefitsData.title}</h2>
						<p>{benefitsData.contentOne}</p>
						<p>{benefitsData.contentTwo}</p>
					</div>
					<div className="sliderTestimonial">

						<Tabs
							defaultActiveKey="1"
							items={items}
							onChange={onChange} />
					</div>
				</div>
				<img src={rightCircle} alt='dots' className='rightCircle' />
				<div className='rightStar'>
					<StarImg />
				</div>
			</div>
			<img src={bottomCircle} alt="bottomCircle" className='bottomCircle' />
		</section>
	);
}

export default TopBenefits;
