import React from "react";
import { useRoutes } from "react-router-dom";
import MainLayout from "../views/layouts/MainLayout";
import PageNotFound from "../views/errors/PageNotFound";
import Home from "../views/modules/Home";
import Contact from "../views/modules/Contact";
import Blog from "../views/modules/Blog/Index";
import Career from "../views/modules/Career/Index"
import About from "../views/modules/About/index";
import HelpAndSupport from "../views/modules/HelpAndSupport/index"
import BlogDetail from "../views/modules/Blog/BlogDetail";
import Solution from "../views/modules/Solutions/index";
import Features from "../views/modules/Features/index";
import Pricing from "../views/modules/Prcing/index";
import FieldInsPectionModule from "../views/modules/FieldInsPectionModule";
import GPSAttendanceModule from "../views/modules/GPSAttendanceModule";
import GPSRoadModule from "../views/modules/GPSRoadModule";
import ProjectTrackingModule from "../views/modules/ProjectTrackingModule";

export default function Router() {
  return useRoutes(RouterConfig);
}


export const RouterConfig = [
  // {
  //   element: <AuthLayout />,
  //   children: [
  //     {
  //       path: "/login",
  //       element: <Login />,
  //     },
  //     {
  //       path: "/forgot-password",
  //       element: <ForgotPassword />,
  //     },
  //     {
  //       path: "/reset/:otp",
  //       element: <ResetPassword />,
  //     },
  //   ],
  // },
  {
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/smartcity-311",
        element: <Home />,
      },
      {
        path: "/contact-us",
        element: <Contact />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/career",
        element: <Career />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/help-support",
        element: < HelpAndSupport />,
      },
      {
        path: "/blog/:id",
        element: <BlogDetail />,
      },
      {
        path: "/solution",
        element: <Solution />
      },
      {
        path: "/features",
        element: <Features />
      },
      {
        path: "/pricing",
        element: <Pricing />
      },
      {
        path:"/field-work-monitoring-module",
        element:<FieldInsPectionModule/>
      },
      {
        path:"/gps-attendance-module",
        element:<GPSAttendanceModule/>
      },
      {
        path:"/gps-road-checking-module",
        element:<GPSRoadModule/>
      },
      {
        path:"/project-tracking-module",
        element:<ProjectTrackingModule/>
      }

    ],

  },
  {
    path: "*",
    element: <PageNotFound />,
  },

];
