import React, { useState } from "react";
import projectTracking from "./ProjectTrackingModule.json";
import "../../../assets/styles/modules/ProjectTrackingModule.less";
import banner from "../../../assets/images/banner.png";
import { Button, Col, Form, Row } from "antd";
import gpsRoadIcon1 from "../../../assets/images/gps-road-icon1.png";
import gpsRoadIcon2 from "../../../assets/images/gps-road-icon2.png";
import gpsRoadIcon3 from "../../../assets/images/gps-road-icon3.png";
import blogDots from "../../../assets/images/BlogDetailsDot.png";
import CityImg from "../../..//assets/images/city_bg1.png";
import projectTrackingImg from "../../../assets/images/projectTrackingImg.png";
import ProjectTrackingModuleAppImg from "../../../assets/images/ProjectTrackingModuleAppImg.png";
import squreDots from "../../../assets/images/squreDots.png";
import featuresOfField from "../../../assets/images/FeaturesofFieldBg.png";
import { ArrowUpOutlined } from "@ant-design/icons";
import { ReactComponent as FieldHand } from "../../../assets/images/icons/handField.svg";
import { ReactComponent as HorizontalLines } from "../../../assets/images/horizontalLines.svg";
import { ReactComponent as FeaturesofFieldLine } from "../../../assets/images/FeaturesofFieldLine.svg";
import SVGIcon from "../../../utils/SVGIcon";
import MapBg from "../../../assets/images/map_bg.png";
import Slider from "react-slick";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { AppDispatch } from "../../../store/app";
import { doContactSubmit } from "../Contact/utils/ContactSlice";
import { Notification } from "../../../config/Global";
import { assignErrorToInput } from "../../../store/api";
import { useDispatch } from "react-redux";
import { ContactRequest } from "../Contact/Validation";
import sendBtn from "../../../assets/images/demoSend.png";
import demoLine from "../../../assets/images/demoLine.png";
import { ReactComponent as RequiredTwo } from "../../../assets/images/require2.svg";
import { ReactComponent as CitizensDots } from "../../../assets/images/CitizensDots.svg";
import projectTrackingModuleImg1 from "../../../assets/images/projectTrackingModule1.jpg";
import projectTrackingModuleImg2 from "../../../assets/images/projectTrackingModule2.jpg";
import projectTrackingModuleImg3 from "../../../assets/images/projectTrackingModule3.jpg";
import administrationComponentIcon1 from "../../../assets/images/administration-component-icon1.png";
import administrationComponentIcon2 from "../../../assets/images/administration-component-icon2.png";
import administrationComponentIcon3 from "../../../assets/images/administration-component-icon3.png";
import administrationComponentIcon4 from "../../../assets/images/administration-component-icon4.png";
import DemoModal from "../../layouts/MainLayout/elements/DemoModal";

const ProjectTrackingModule = () => {
  const solutionforCitizens = projectTracking?.solutionforCitizens;
  const ptmSection = projectTracking?.ptmSection;
  const demo = projectTracking?.demo;
  const [form] = Form.useForm();
  const images = [gpsRoadIcon1, gpsRoadIcon2, gpsRoadIcon3];
  const administrationComponentIcon = [
    administrationComponentIcon1,
    administrationComponentIcon2,
    administrationComponentIcon3,
    administrationComponentIcon4,
  ];

  const [openDemoModal, setOpenDemoModal] = useState<boolean>(false);

  const handleDemoModal = () => {
    setOpenDemoModal(!openDemoModal);
  };
  const dispatch: AppDispatch = useDispatch();

  const handleSubmit = (data: any) => {
    const payload = `full_name=${data.full_name}&email=${data.email}&subject=${data.subject}&message=${data.message}`;

    dispatch(doContactSubmit(payload))
      .then((res: any) => {
        res?.data?.NOTIFICATION.map((err: string) =>
          Notification.success({
            message: err,
          })
        );
        form.resetFields();
      })
      .catch((error: any) => {
        assignErrorToInput(form, error?.NOTIFICATION);
        error?.response?.data?.NOTIFICATION.map((err: string) =>
          Notification.error({
            message: err,
          })
        );
        // form.resetFields();
      });
  };
  const SlickArrowLeft = ({ currentslide, ...props }: any) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentslide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentslide === 0 ? true : false}
      type="button"
    >
      <svg
        className="img"
        xmlns="http://www.w3.org/2000/svg"
        // fill="black"
        viewBox="0 0 48 48"
        id="arrow-left"
      >
        <path d="M17.85 35.25 6.6 23.95 17.85 12.7l1.65 1.6-8.5 8.55h30.4v2.25H11l8.5 8.5Z" />
      </svg>
    </button>
  );
  const SlickArrowRight = ({ currentslide, slidecount, ...props }: any) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentslide === slidecount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentslide === slidecount - 1 ? true : false}
      type="button"
    >
      <svg
        className="img"
        xmlns="http://www.w3.org/2000/svg"
        // fill="black"
        viewBox="0 0 48 48"
        id="arrow-left"
      >
        <path d="M17.85 35.25 6.6 23.95 17.85 12.7l1.65 1.6-8.5 8.55h30.4v2.25H11l8.5 8.5Z" />
      </svg>
    </button>
  );

  const settings = {
    dots: false,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    autoplay: true,
    centerMode: true,
    centerPadding: "60px",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: "00",
        },
      },
    ],
  };

  const testimonialItem = (testimonial: any) => (
    <div className="testimonialItem" key={testimonial.id}>
      <p>“{testimonial.content}”</p>
      <strong className="name">{testimonial.name}</strong>
      <span className="post">{testimonial.post}</span>
    </div>
  );

	const testimonialData = [
		{
		  id: 1,
		  content:
			"From last one year, our Department is using GPS Attendance for marking attendance online that helps make real-time entry of employees. It helps maintain discipline in our department.",
		  name: "Dr. Govind Makwana",
		  post: "Deputy Health Officer of South Zone, Maninagar",
		},
		{
		  id: 2,
		  content:
			"I am using GPS attendance app from last one year and it works very smoothly. Now, our entire department is working with smooth management. It allows tracking every single employee.",
		  name: "Harpalsinh Zala",
		  post: "Additional City Engineer of West Zone, Naranpura",
		},
	  ];

  return (
    <div id="projectTracking" className="GPSAttendanceModule">
      <section className="banner" style={{ backgroundImage: `URL(${banner})` }}>
        <h1 className="title">{projectTracking.FieldInsPectionModule.title}</h1>
      </section>
      <section className="SolutionForCitizens">
        <img src={blogDots} alt="blogDots" className="Dotted_1" />
        <div className="container">
          <Row gutter={[30, 30]}>
            <Col xs={24} lg={12}>
              <div className="citizensSectionWrap">
                <div className="citizensSectionLeft">
                  <div className="citizensImageBlogOne">
                    <img src={projectTrackingModuleImg1} alt="projectTrackingModuleImg1" />
                  </div>
                  <div className="citizensImageBlogTwo">
                    <img src={projectTrackingModuleImg2} alt="projectTrackingModuleImg2" />
                  </div>
                </div>

                <div className="citizensSectionRight">
                  <img src={projectTrackingModuleImg3} alt="projectTrackingModuleImg3" />
                </div>
              </div>
            </Col>
            <Col xs={24} lg={12}>
            <h2 className="innerTitle">{solutionforCitizens.title}</h2>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: solutionforCitizens.content,
                }}
              ></div>
              <div className="Road-Checking-Module-list">
                {solutionforCitizens?.innerContent.map(
                  (item: any, index: number) => {
                    return (
                      <div key={index} className="citizensPicture">
                        <div className="citizensPictureInner">
                          <div className="citizensImages">
                            <img src={images[index]} alt={item.key} />
                          </div>
                          <div className="citizensContent">
                            <h3>{item.title}</h3>
                          </div>
                        </div>
                        <div className="arrowIcon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="28"
                            viewBox="0 0 16 28"
                            fill="none"
                          >
                            <path
                              d="M7.29289 27.2071C7.68342 27.5976 8.31658 27.5976 8.70711 27.2071L15.0711 20.8431C15.4616 20.4526 15.4616 19.8195 15.0711 19.4289C14.6805 19.0384 14.0474 19.0384 13.6569 19.4289L8 25.0858L2.34315 19.4289C1.95262 19.0384 1.31946 19.0384 0.928933 19.4289C0.538408 19.8195 0.538408 20.4526 0.928933 20.8431L7.29289 27.2071ZM9 26.5L9 8.74228e-08L7 -8.74228e-08L7 26.5L9 26.5Z"
                              fill="#79C423"
                            />
                          </svg>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </Col>
          </Row>
        </div>
        <img src={CityImg} alt="" className="CityImg" />
      </section>
      <section className="reportSection">
        <div className="container">
          <div className="gps-road-app-work">
            <div className="gps-work-left-content">
              <div className="titleContent">
                <h2 className="Title">{ptmSection.title}</h2>
                <p className="description">{ptmSection.content}</p>
              </div>
              <ul className="gpsRoadList">
                {projectTracking.ptmSection.management.map((item: any) => {
                  return (
                    <li>
                      <span className="arrowIcon">
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 12 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.4149 1.45923L10.9557 5.00006L7.4149 8.54089M1.03906 5.00006H10.8566"
                            stroke="#2490EB"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      {item.description}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="gps-road-work-img">
              <img src={projectTrackingImg} alt={"projectTrackingImg"} />
            </div>
          </div>
          <div className="squreDotsLeft">
            <img src={squreDots} alt="RequiredLeftCircle" />
          </div>
          <div className="squreDotsRight">
            <img src={squreDots} alt="RequiredLeftCircle" />
          </div>
          <div className="secondLine">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1920"
              height="381"
              viewBox="0 0 1920 381"
              fill="none"
            >
              <path
                d="M2021.75 3.04193C1940.75 48.614 1721.92 124.348 1494.6 62.7073C1210.45 -14.3434 1029.78 201.951 940.145 278.737C850.514 355.523 739.732 413.004 389.704 350.88C39.6764 288.756 96.5348 264.737 -30.0018 332"
                stroke="white"
                stroke-opacity="0.04"
                stroke-width="7"
              />
            </svg>
          </div>
        </div>
      </section>
      <section className="ProjectTrackingModule">
        <div className="container">
          <div className="ProjectTrackingModuleInner">
            <div className="titleContent">
              <h2 className="Title">
                {projectTracking.projectTrackingModuleSection.title}
              </h2>
              <p className="description">
                {projectTracking.projectTrackingModuleSection.description}
              </p>
            </div>
            <div className="gps-road-img">
              <img src={ProjectTrackingModuleAppImg} alt={"ProjectTrackingModuleAppImg"} />
            </div>
          </div>
        </div>
      </section>

      <section
        className="featuresOfField"
        style={{ backgroundImage: `URL(${featuresOfField})` }}
      >
        <div className="featuresOfFieldLines">
          <FeaturesofFieldLine />
        </div>
        <div className="container">
          <div className="leftDots">
            <HorizontalLines />
          </div>
          <div className="fieldInspectionContent">
            <div className="fieldInspectionimg">
              <FieldHand />
              <h2>{projectTracking.inspectionFeatures.title}</h2>
            </div>
          </div>
        </div>
        <div className="rightDots">
          <HorizontalLines />
        </div>
      </section>
      <section className="administrationSection">
        <div className="container">
          <div className="titleContent">
            <h2 className="Title">
              {projectTracking.administrationComponent.title}
            </h2>
            <p className="description">
              {projectTracking.administrationComponent.description}
            </p>
          </div>
          <div className="administrationInnerSection">
            <div className="administrationMain">
              {projectTracking.administrationComponent.administrationComponent.map(
                (item: any, index: number) => {
                  return (
                    <div className="administrationBox" key={index}>
                      <div className="administrationBoxInner">
                        <div className="icon">
                          <img
                            src={administrationComponentIcon[index]}
                            alt={item.key}
                          />
                        </div>
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </section>

      <section id="Testimonial">
        <div
          className="sliderContainer"
          style={{ backgroundImage: `url(${MapBg})` }}
        >
          <SVGIcon icon="qoute" className="qoute" />
          <Slider {...settings}>
            {testimonialData?.map((testimonial: any) =>
              testimonialItem(testimonial)
            )}
          </Slider>
        </div>
        <div className="bubbles">
          <span className="bubble one"></span>
          <span className="bubble two"></span>
          <span className="bubble three"></span>
          <span className="bubble four"></span>
          <span className="bubble five"></span>
        </div>
      </section>
      <section className="demoForm">
        <div className="reportSection">
          <div className="featuresOfFieldLines">
            <img src={demoLine} alt="demoLine" />
          </div>
          <div className="miniContainer">
            <Row gutter={[30, 30]}>
              <Col xs={24} lg={14}>
                {demo?.management.map((item: any, index: number) => {
                  return (
                    <div key={index} className="crmServiceContent">
                      <h2 className="crmServiceTitle">{item.title}</h2>
                      <ul className="crmServiceList">
                        {item?.list.length > 0 &&
                          item?.list.map((item: any) => {
                            return (
                              <li>
                                <ArrowUpOutlined />
                                {item.content}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  );
                })}
              </Col>
              <Col xs={24} lg={10}>
                <div className="contactForm">
                  <FormBox form={form} onFinish={handleSubmit}>
                    <h2 className="crmServiceTitle">Request a Demo</h2>
                    <div className="nameInput">
                      <InputBox.Text
                        required
                        label="Your Name *"
                        name="full_name"
                        rules={ContactRequest().full_name("Your Name")}
                        placeholder="Enter your name"
                      />

                      <InputBox.Text
                        required
                        label="Mobile No *"
                        name="Mobile No"
                        // rules={ContactRequest().email("Email")}
                        placeholder="Enter your mobile no"
                      />
                    </div>
                    <InputBox.Text
                      required
                      label="Your Email *"
                      name="email"
                      rules={ContactRequest().email("Email")}
                      placeholder="Enter your email id"
                    />
                    <InputBox.TextArea
                      required
                      label="Write Comment *"
                      name="message"
                      rules={ContactRequest().message("Your Massage")}
                      placeholder="Enter your Comment"
                      rows={1}
                    />
                    <Button htmlType="submit">
                      Submit
                      <img src={sendBtn} alt="send" />
                    </Button>
                  </FormBox>
                </div>
              </Col>
            </Row>
            <div className="forCitizens">
              <div className="requiredTwo">
                <RequiredTwo />
              </div>
              <div className="CitizensDots">
                <CitizensDots />
              </div>
              <span className="subTitle">{projectTracking.solutionCitizens.subtitle}</span>
              <h2>{projectTracking.solutionCitizens.title}</h2>
              <Button
                onClick={() => {
                  handleDemoModal();
                }}
                title="View Details"
                className="ant-btn ant-btn-default buttonLink primaryColor outline demoBtn"
              >
                <span>{projectTracking.solutionCitizens.button}</span>
              </Button>

              <div className="CitizensDotsBottom">
                <CitizensDots />
              </div>

              <div className="requiredRightCircle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="242"
                  height="241"
                  viewBox="0 0 242 241"
                  fill="none"
                >
                  <g opacity="0.31">
                    <circle
                      cx="121.055"
                      cy="120.5"
                      r="120.5"
                      fill="url(#paint0_linear_829_3894)"
                    />
                    <circle
                      cx="121.055"
                      cy="120.5"
                      r="120.5"
                      fill="url(#paint1_linear_829_3894)"
                    />
                    <circle
                      cx="121.471"
                      cy="124.892"
                      r="77.0294"
                      transform="rotate(106.834 121.471 124.892)"
                      fill="url(#paint2_linear_829_3894)"
                    />
                    <circle
                      cx="121.471"
                      cy="124.892"
                      r="77.0294"
                      transform="rotate(106.834 121.471 124.892)"
                      fill="url(#paint3_linear_829_3894)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_829_3894"
                      x1="121.055"
                      y1="0"
                      x2="121.055"
                      y2="241"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_829_3894"
                      x1="121.055"
                      y1="0"
                      x2="121.055"
                      y2="241"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_829_3894"
                      x1="121.471"
                      y1="47.8628"
                      x2="121.471"
                      y2="201.922"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_829_3894"
                      x1="121.471"
                      y1="47.8628"
                      x2="121.471"
                      y2="201.922"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="squreDotsRight">
          <img src={squreDots} alt="RequiredLeftCircle" />
        </div>
      </section>
      <DemoModal
        openDemoModal={openDemoModal}
        closeDemoModal={handleDemoModal}
      />
    </div>
  );
};

export default ProjectTrackingModule;
