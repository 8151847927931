import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from '../../../store/app';
import { getBlogDetails } from './Utils/BlogSlice';
import PageSpinner from '../../../components/PageSpinner/PageSpinner';
import InnerBanner from '../../../components/Sections/InnerBanner';
import "../../../assets/styles/modules/blogDetails.less"
import { Col, Row } from 'antd';
import blogDots from "../../../assets/images/BlogDetailsDot.png";

import BlogDetailsLeft from './component/BlogDetailsLeft';
import BlogDetailsRight from './component/BlogDetailsRight';
// import SEOHeader from '../../../config/SEOHeader';

const BlogDetail = () => {
    const { id } = useParams()
    const dispatch: AppDispatch = useDispatch()
    const [blogDetails, setBlogDetails] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true)
    const page = useSelector((state: RootState) => state.BLOG.pagination)

    useEffect(() => {
        dispatch(getBlogDetails(id)).then((data: any) => {
            setBlogDetails(data)
            setLoading(false)
        }).catch(() => setLoading(false))
            .finally(() => setLoading(false));
    }, [id, dispatch, page])

    // const pageMetatag = {
    //     title: blogDetails?.seo_title,
    //     description: blogDetails?.seo_description,
    //     keyword: blogDetails?.seo_keywords,

    // };


    return !loading ? (
        <>
            {/* <SEOHeader pageMetatag={pageMetatag} /> */}
            <section id='blogDetails'>
                <InnerBanner title='Blog' />
                <div className='main'>
                    <img src={blogDots} alt='blogDots' className='Dotted_1' />
                    <div className="container">
                        <Row gutter={[30, 60]}>
                            <Col xs={24} md={24} lg={16}>
                                <BlogDetailsLeft blogDetails={blogDetails.blog} />
                            </Col>
                            <Col xs={24} md={24} lg={8}>
                                <BlogDetailsRight
                                    latest_blogs={blogDetails.latest_blogs}
                                    tags={blogDetails.tags} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
        </>
    ) : <PageSpinner />
}

export default BlogDetail