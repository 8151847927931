import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet-async";
import { useLocation } from 'react-router-dom';
import SeoTitle from "./SeoTitles.json"

const HelmetComponent = () => {
const [seoDetails, setSeoDetails] = useState<any>();
const location = useLocation();
useEffect (() => {
const metatags = SeoTitle.title.find((item:any) => item.slug === location.pathname )
setSeoDetails(metatags)
},[location])

const defaultMetatag = {
    title: "Best Solution for Non-emergency Civic Services in Your City",
    description:
      "Smart City 311 is an award-winning app to get any non-emergency services like doctor appointments, complaints, etc. Civilians can also get online certificates.",
    keyword: "digital signage software, Digital signage solutions",
  };

return (
  <>
      <Helmet prioritizeSeoTags>
          <title>{seoDetails?.metaTitle ?? defaultMetatag.title }</title>
          <meta
              name="description"
              content={seoDetails?.metaDescription ?? defaultMetatag.description}
          />
      </Helmet>
  </>
);
}

export default HelmetComponent