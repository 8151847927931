import { Row } from "antd";
import React from "react";
import RequirementSection from "../../../components/Sections/RequirementSection";
import InnerBanner from "../../../components/Sections/InnerBanner";
import "../../../assets/styles/modules/features.less";
import featuresData from "./Features.json";
import { ReactComponent as FeaturesImg1 } from "../../../assets/images/icons/features1.svg";
import { ReactComponent as FeaturesImg2 } from "../../../assets/images/icons/features2.svg";
import { ReactComponent as FeaturesImg3 } from "../../../assets/images/icons/features3.svg";
import { ReactComponent as FeaturesImg4 } from "../../../assets/images/icons/features4.svg";
import { ReactComponent as FeaturesImg5 } from "../../../assets/images/icons/features5.svg";
import { ReactComponent as FeaturesImg6 } from "../../../assets/images/icons/features6.svg";
import { ReactComponent as FeaturesImg7 } from "../../../assets/images/icons/features7.svg";
import { ReactComponent as FeaturesImg8 } from "../../../assets/images/icons/features8.svg";
import { ReactComponent as FeaturesImg9 } from "../../../assets/images/icons/features9.svg";
import { ReactComponent as FeaturesImg10 } from "../../../assets/images/icons/Complain.svg";
import { ReactComponent as FeaturesImg11 } from "../../../assets/images/icons/NearMe.svg";
import { ReactComponent as FeaturesImg12 } from "../../../assets/images/icons/Property.svg";
import { ReactComponent as FeaturesImg13 } from "../../../assets/images/icons/Certificate.svg";
import { ReactComponent as FeaturesImg14 } from "../../../assets/images/icons/Helpline.svg";
import { ReactComponent as FeaturesImg15 } from "../../../assets/images/icons/Appointment.svg";
import bgImage from "../../../assets/images/featuresBg.png";
import vertical from "../../../assets/images/verticallyDots.png";


const features = () => {
  const featuresImg = [
    <FeaturesImg1 />,
    <FeaturesImg2 />,
    <FeaturesImg3 />,
    <FeaturesImg4 />,
    <FeaturesImg5 />,
    <FeaturesImg6 />,
    <FeaturesImg7 />,
    <FeaturesImg8 />,
    <FeaturesImg9 />,
    <FeaturesImg10 />,
    <FeaturesImg11 />,
    <FeaturesImg12 />,
    <FeaturesImg13 />,
    <FeaturesImg14 />,
    <FeaturesImg15 />,
  ];
  return (
      <main id="features">
        <InnerBanner title="Features" />
        <div className="features">
          <div className="container">
            {featuresData.length > 0 &&
              featuresData.map((item: any, index: number) => {
                return (
                  <div className="featuresContent" key={item.id}>
                    <div className="featuresContentBgImg">
                      <img src={bgImage} alt="bgImg" />
                    </div>
                    <div className="boxContent">
                      <div className="featuresHeader">
                        <div className="featuresIcon">{featuresImg[index]}</div>
                        <h2 className="featuresTitle">{item.title}</h2>
                      </div>
                      <div
                        className="featuresInnerContent"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></div>                       
                    </div>
                  </div>
                );
              })}
          </div>
          <img src={vertical} alt="verticalDots" className="verticalDots" />
        </div>
        <div className="container">
          <Row className="requirement" justify={"center"} gutter={90}>
            <RequirementSection
              title="Let’s Grow Together"
              content="<p> Just take a step forward, and we can make history</p>"
              subTitle="Send Your Requirement"
             
            />
          </Row>
        </div>
      </main>
  );
};

export default features;
