import React from 'react'
import InnerBanner from '../../../components/Sections/InnerBanner';
import solutionJson from "./Solution.json";
import solutionOne from "../../../assets/images/solutionOne.png"
import solutionTwo from "../../../assets/images/solutionTwo.png"
import solutionThree from "../../../assets/images/solutionThree.png"
import solutionFour from "../../../assets/images/solutionFour.png"
import solutionFive from "../../../assets/images/solutionFive.png"
import dots from "../../../assets/images/aboutDotBollOne.svg"
import verticalDots from "../../../assets/images/verticallyDots.png"
import "../../../assets/styles/modules/solution.less"
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import SVGIcon from '../../../utils/SVGIcon';

const Solutions = () => {
    const solutionImages = [
        solutionOne,
        solutionTwo,
        solutionThree,
        solutionFour,
        solutionFive,
    ]
    return (
        <>
            <InnerBanner title="Solutions" />
            <main id="solutions">
                <div className='firstSectionDots'>
                    <img src={dots} alt='dots' className='roundDots' />
                    {/* for 3 section img  */}
                    <img src={verticalDots} alt='dots' className='thirdSectionImgLeft' />
                    <img src={verticalDots} alt='dots' className='thirdSectionImgRight' />
                    {/* for 5 section img  */}
                    <img src={verticalDots} alt='dots' className='lastSectionImgLeft' />
                </div>
                {solutionJson?.map((item: any, index: number) => {
                    return (
                        <section key={item.id} className='solutionDetails'>
                            <div className='container'>
                                <Row gutter={[30, 10]} align={'middle'} className='innerContent'>
                                    <Col xs={24} lg={12} key={item.id} className='solutionImg'>
                                        <img src={solutionImages[index]} alt={item.title} />
                                        <span className='dots'></span>
                                    </Col>
                                    <Col xs={24} lg={12} className='solutionContent' >
                                        {item.title && <h2 className='contentTitle'>{item.title}</h2>}
                                        {item.content && <div dangerouslySetInnerHTML={{ __html: item.content }} className='content'></div>}
                                        <Link to={item.slug} target="_blank" title='Read More' rel="noreferrer" className="link buttonLink">
                                            Read More <SVGIcon icon="rightTopArrow" />
                                        </Link>
                                        <span className='dots'></span>
                                    </Col>

                                </Row>
                            </div>
                        </section>
                    )
                })}
            </main>
        </>
    )
}

export default Solutions