import React from 'react'
import banner from "../../assets/images/banner.png"

interface MyComponent {
    title: string
    subTitle?: string
    tag?: string
}
const InnerBanner: React.FC<MyComponent> = ({ title, subTitle,tag  }) => {
    return (
        <div className='banner' style={{ backgroundImage: `URL(${banner})` }}>
            <h1 className="title">
                {title}
            </h1>
            {subTitle && <h4>{subTitle}</h4>}
            {tag && <h3>
                {tag}
            </h3>}
        </div>
    )
}

export default InnerBanner